<template>
	<div class="single-trailer">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$tc("terms.trailer")}}</div>
			<div class="btn-toolbar mb-2 mb-md-0" v-if="mayEdit">
				<small-button :text="$t('terms.edit')" @click.native="goTo({name: 'trailer-edit',params:{id:routeEntity.id}})" />
			</div>
		</div>
		<spinner v-if="isLoading"/>
		<template v-else>
			<form-container>
				<div class="part-label col-12">{{$t("titles.trailerDetails")}}</div>
				<field-input :label='$t("labels.serial")' :value="routeEntity.serialNumber" :disabled="true" />
				<field-input :label='$t("labels.numberPlate")' :value="routeEntity.numberPlate" :disabled="true"  />
				<entity-select :label='$t("labels.machine")' :value="routeEntity.machine !== null ? routeEntity.machine : ''" entity="Machine" search-property="name" display-property="name" value-property="@id" :disabled="true"  />
			</form-container>
		</template>
	</div>
</template>

<script>
	import Spinner from "@/components/loaders/Spinner";
	import isGranted from "@/mixin/isGranted";
	import {mapState} from "vuex";
	import SmallButton from '@/components/buttons/SmallButton.vue';
	import DataConversionMethods from '@/mixin/dataConversionMethods.js';
	import permissions from "@/permissions";
	import FieldInput from "@/components/inputs/FieldInput";
	import EntitySelect from "@/components/inputs/EntitySelect";
	import FormContainer from "@/components/container/FormContainer";

	export default {
		name: "SingleTrailer",
		components: {FormContainer, EntitySelect, FieldInput, Spinner, SmallButton},
		mixins: [isGranted, DataConversionMethods],
		data(){
			return {
				isLoading: true
			}
		},
		computed: {
			...mapState('concrete', {
				trailers: 'trailers',
				viewingEntity: 'viewingEntity',
			}),
			routeEntity(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.trailers[this.$route.params.id] !== 'undefined') ? this.trailers[this.$route.params.id] : false ;
			},
			mayEdit(){
				if(this.hasGlobalSuperAdmin || this.isGranted(permissions.TRAILER.UPDATE) )
					return true;
				
				return (this.viewingEntity !== false) ? this.isGranted(permissions.TRAILER.UPDATE,this.viewingEntity) : false;
			}
		},
		methods: {
			goTo(route){
				this.$router.push(route);
			},
			fetchEntity(){
				if(this.routeEntity === false)
				{
					this.isLoading = true;
					this.$store.dispatch('concrete/genericEntityRequest',{entity: {'id': this.$route.params.id,'@type':'Trailer'},parameters:{explode: 'machine:name'}})
					.catch(error => {
						console.log(error);
					})
					.then(() => {
						this.isLoading = false;
					},() => {
						this.isLoading = false;
					})
				}
				else
				{
					this.isLoading = false;
				}
			}
		},
		mounted()
		{
			this.fetchEntity();
		},
		watch: {
			$route: {
				deep:true,
				handler: function(){
					this.fetchEntity();
				}
			}
		}
	};
</script>

<style scoped>

</style>
